/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxIndeterminateIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21 24H3a3 3 0 01-3-3V3a3 3 0 013-3h18a3 3 0 013 3v18a3 3 0 01-3 3zM5.6 10.762A1.239 1.239 0 004.364 12a1.24 1.24 0 001.238 1.238h12.8A1.24 1.24 0 0019.637 12a1.239 1.239 0 00-1.238-1.238z" />
	</svg>
))
CheckboxIndeterminateIconSvg.displayName = 'CheckboxIndeterminateIconSvg'

const CheckboxIndeterminateIcon = forwardRef((props, ref) => (
	<Icon component={CheckboxIndeterminateIconSvg} ref={ref} {...props} />
))
CheckboxIndeterminateIcon.displayName = 'CheckboxIndeterminateIcon'

CheckboxIndeterminateIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxIndeterminateIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxIndeterminateIcon
export { CheckboxIndeterminateIconSvg }
